import React, { Component } from "react";
import barrow from "../../common/images/back-arrow.svg";
import eyeoff from "../../common/images/eye-off.svg";
import mc from "../../common/images/master-card.svg";
import vc from "../../common/images/visa-card.svg";
import jcb from "../../common/images/jcb-card.svg";
import ae from "../../common/images/ae-card.svg";

class Home extends Component {
  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <a href={() => false} className="arrow-back">
                <img src={barrow} alt="back" />
              </a>
            </div>
            <div className="ha-middle">Debit/Credit Card</div>
            <div className="ha-rhs ha-rhs-link"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="card-frame">
              <div className="cf-title">
                <h3>Credit / Debit Card</h3>
              </div>
              <div className="cf-card-form">
                <div className="cf-card-input">
                  <label>Card Number</label>
                  <input type="text" placeholder="Exp: 1234 5678 9101 12131" />
                </div>
                <div className="cf-card-img">
                  <img src={mc} alt="Card" />
                  <img src={vc} alt="Card" />
                  <img src={jcb} alt="Card" />
                  <img src={ae} alt="Card" />
                </div>

                <div className="cf-card-half">
                  <div className="cf-card-input cf-ci-half">
                    <label>Valid Thru</label>

                    <div className="cf-flex">
                      <div className="cf-month">
                        <input type="text" placeholder="mm" />
                      </div>
                      <em>/</em>
                      <div className="cf-year">
                        <input type="text" placeholder="yy" />
                      </div>
                    </div>
                  </div>
                  <div className="cf-card-input">
                    <label>CVV</label>
                    <div className="cf-card-fold rel">
                      <input type="text" placeholder="123" />
                      <img src={eyeoff} className="eye-img" alt="cvv" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="save-btn">
              <a href={() => false} className="button button-full">
                Save
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
