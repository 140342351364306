import { takeEvery, call, put } from "redux-saga/effects";
import { GET_PRODUCT_DETAIL, SET_PRODUCT_DETAIL } from "../actions";
import { unquieID, apiUrl } from "../components/Settings/Config";
import Axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";

export const watchGetProductDetail = function* () {
  yield takeEvery(GET_PRODUCT_DETAIL, workerGetProductDetail);
};

function* workerGetProductDetail({ proSlug }) {
  try {
    var availabilityId = cookie.load("defaultAvilablityId") || "";
    var outlet = cookie.load("orderOutletId") || "";
    var userID = cookie.load("UserId") || "";
    var favID = userID !== "" ? "&fav_cust_id=" + userID : "";
    var deliveryDate = cookie.load("deliveryDate") || "";
    var delivery_Date = "";
    if (deliveryDate !== "") {
      var deliveryDates = deliveryDate.split("/");
      delivery_Date =
        deliveryDates[2] + "-" + deliveryDates[1] + "-" + deliveryDates[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }
    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    const uri =
      apiUrl +
      "catalogs/productdetails?unquieid=" +
      unquieID +
      "&product_slug=" +
      proSlug +
      "&availability=" +
      availabilityId +
      "&outlet=" +
      outlet +
      favID +
      spicelPride;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCT_DETAIL, value: resultArr });
  } catch {
    console.log("Get Products Failed");
  }
}
