import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import ContentLoader from "react-content-loader";
import { unquieID, apiUrl } from "../Settings/Config";
import {
  GET_STORE_DETAILS,
  GET_CATEGORY_LIST,
  GET_PRODUCT_LIST,
} from "../../actions";
import Cart from "../Cart/Cart";
import cart from "../../common/images/cart.svg";
import { stripslashes, encodeValue } from "../Settings/SettingHelper";
import ProductList from "./ProductList";
import fadd from "../../common/images/favorite-add.svg";
import factive from "../../common/images/favorite-active.svg";

import share1 from "../../common/images/share.svg";
import barrow from "../../common/images/back-arrow.svg";
import restaurantclose from "../../common/images/restaurant-close.svg";
var qs = require("qs");
class Restaurants extends Component {
  constructor(props) {
    super(props);
    var storeSlug = this.props.match?.params?.storeSlug || "";
    var categorySlug = this.props.match?.params?.categorySlug || "";
    var productSlug = this.props.match?.params?.productSlug || "";

    if (storeSlug === "") {
      this.props.history.push("/");
    }
    this.state = {
      storeSlug: storeSlug,
      storeDetails: "",
      storeStatus: "",
      storeLoading: true,
      storeID: "",
      categoryList: [],
      currentCategory: categorySlug,
      productSlug: productSlug,
      productList: [],
      productLoading: true,
      favouriteSote: "No",
      cartdetails: [],
    };
  }
  componentDidMount() {
    var latitude = cookie.load("deliveryaddresslat") || "";
    var longitude = cookie.load("deliveryaddresslong") || "";
    this.props.getStoreDetails(
      "&storeSlug=" +
        this.state.storeSlug +
        "&latitude=" +
        latitude +
        "&longitude=" +
        longitude +
        "&customerID=" +
        cookie.load("customerID")
    );
  }
  componentWillReceiveProps(PropsDt) {
    if (this.state.storeDetails !== PropsDt.storeDetails) {
      this.setState({ storeDetails: PropsDt.storeDetails }, function () {
        if (Object.keys(PropsDt.storeDetails).length > 0) {
          var storeID = this.state.storeDetails.storeID;
          var favourite = this.state.storeDetails.favourite;
          this.setState(
            { storeID: storeID, storeLoading: false, favouriteSote: favourite },
            function () {
              this.props.getCategoryList("&storeID=" + encodeValue(storeID));
            }
          );
        }
      });
    }
    if (this.state.storeStatus !== PropsDt.storeStatus) {
      if (PropsDt.storeStatus === "fail") {
        this.props.history.push("/");
      }
    }
    if (this.state.categoryList !== PropsDt.categoryList) {
      if (PropsDt.categoryList.length > 0) {
        this.setState({ categoryList: PropsDt.categoryList }, function () {
          if (this.state.categoryList.length > 0) {
            if (this.state.currentCategory !== "") {
              this.productsList();
            } else {
              this.setState(
                {
                  currentCategory: this.state.categoryList[0].catSlug,
                },
                function () {
                  this.productsList();
                }
              );
            }
          }
        });
      } else {
        this.setState({ currentCategory: "", productLoading: false });
      }
    }

    if (
      this.state.currentCategory !== "" &&
      this.state.currentCategory !== PropsDt.match.params.categorySlug &&
      PropsDt.match.params?.categorySlug !== ""
    ) {
      this.setState(
        {
          currentCategory: PropsDt.match.params.categorySlug,
          productLoading: true,
        },
        function () {
          this.productsList();
        }
      );
    }
    if (this.state.productList !== PropsDt.productList) {
      this.setState(
        {
          productList: PropsDt.productList,
        },
        function () {
          if (PropsDt.productList.length > 0) {
            this.setState({ productLoading: false });
          }
        }
      );
    }
    if (this.state.cartdetails !== PropsDt.cartdetails) {
      this.setState({ cartdetails: PropsDt.cartdetails });
    }
  }
  productsList() {
    if (this.state.currentCategory !== "") {
      this.props.getProductList(
        "&cateSlug=" +
          this.state.currentCategory +
          "&storeID=" +
          encodeValue(this.state.storeDetails.storeID)
      );
    }
  }
  addFavourite() {
    var favUrl = "";
    var updFav = "";
    if (this.state.favouriteSote === "No") {
      favUrl = "addFavourite";
      updFav = "Yes";
    } else {
      favUrl = "removeFavourite";
      updFav = "No";
    }
    var postObject = {
      unquieid: unquieID,
      shopID: encodeValue(this.state.storeDetails.storeID),
      customerID: cookie.load("customerID"),
    };

    axios
      .post(apiUrl + "store/" + favUrl, qs.stringify(postObject))
      .then((res) => {
        // hideLoader("proDtIndex-" + IndexFlg, "Idtext");
        this.setState({ favouriteSote: updFav });
        if (res.data.status === "ok") {
          /* 
          this.handleShowAlertFun(
            "Success",
            "Great choice! Item added to your cart."
          ); */
        } else if (res.data.status === "error") {
          /*   var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("Error", errMsgtxt); */
        }

        return false;
      });
  }

  render() {
    const { storeDetails } = this.state;
    return (
      <div className={`main-div`}>
        <Cart />
        <div className="top-tool">
          <div className="container">
            <div className="tt-lhs">
              <Link to={"/restaurants"} className="arrow-bk">
                <img src={barrow} alt="back" />
              </Link>
            </div>

            <div className="tt-rhs">
              <ul>
                <li>
                  <a href={() => false} onClick={this.addFavourite.bind(this)}>
                    {this.state.favouriteSote === "Yes" ? (
                      <img src={factive} alt="removefav" />
                    ) : (
                      <img src={fadd} alt="addfav" />
                    )}
                  </a>
                </li>
                <li>
                  <Link to={"/cart"}>
                    <img src={cart} alt="cart" />
                    {Object.keys(this.state.cartdetails).length > 0 && (
                      <span className="wish-count">
                        {this.state.cartdetails.totalItem}+
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <a href={() => false}>
                    <img src={share1} alt="share" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {this.state.storeLoading === true ? (
          <div className="content-body detail-cb rel">
            <div className="container">
              <div className="detail-outlet-margin">
                <ContentLoader viewBox="0 0 380 70">
                  <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                  <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                  <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                </ContentLoader>
              </div>
            </div>
          </div>
        ) : (
          storeDetails !== "" && (
            <div className={`content-body detail-cb rel`}>
              <div className="container">
                <div className="detail-banner">
                  <img
                    src={storeDetails.bannerImage}
                    alt={storeDetails.storeName}
                  />
                </div>
                <div>
                  <div className="detail-outlet">
                    <div
                      className={`ot-img ${
                        storeDetails?.storeOpen === "0"
                          ? "storeclosed inner-store-close"
                          : ""
                      }`}
                    >
                      <img
                        src={storeDetails.storeImage}
                        alt={storeDetails.storeName}
                      />
                    </div>
                    <div className="ot-info">
                      {storeDetails.storeOpen === "0" && (
                        <div className="storeclosed-msg">
                          <img
                            src={restaurantclose}
                            alt="Restaurant is closed"
                          />
                          <span>Restaurant is closed</span>
                        </div>
                      )}
                      <div
                        className={`${
                          storeDetails?.storeOpen === "0"
                            ? "storeclosed inner-store-close"
                            : ""
                        }`}
                      >
                        <h3 className="ot-title">{storeDetails.storeName}</h3>
                        <div className="km-rating">
                          <strong>
                            {" "}
                            {storeDetails.distance !== ""
                              ? parseFloat(storeDetails.distance).toFixed("2")
                              : "0"}{" "}
                            km
                          </strong>
                          {storeDetails.Rating !== "" &&
                            storeDetails.Rating !== null && (
                              <span>
                                {storeDetails.Rating} (
                                {storeDetails.totalRating})
                              </span>
                            )}
                        </div>
                        {storeDetails.storeTimeInfo !== "" && (
                          <div className="op-time">
                            {storeDetails.storeTimeInfo}
                          </div>
                        )}
                        {storeDetails.tagName !== "" && (
                          <div className="ot-keyword">
                            {storeDetails.tagName}
                          </div>
                        )}
                        {storeDetails.offerInfo !== "" &&
                          storeDetails.offerInfo !== null && (
                            <div className="ot-offers">
                              {storeDetails.offerInfo}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {this.state.categoryList.length > 0 && (
                    <div
                      className={`pp-tags ${
                        storeDetails?.storeOpen === "0"
                          ? "storeclosed inner-store-close"
                          : ""
                      }`}
                    >
                      <ul>
                        {this.state.categoryList.map((item, index) => {
                          return (
                            <li
                              className={
                                item.catSlug === this.state.currentCategory
                                  ? "active"
                                  : ""
                              }
                              key={index}
                            >
                              <Link
                                to={
                                  "/restaurant/" +
                                  this.state.storeSlug +
                                  "/" +
                                  item.catSlug
                                }
                              >
                                {stripslashes(item.categoryName)}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                <ProductList
                  product={this.state}
                  storeOpen={storeDetails.storeOpen}
                />
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var store_details = [];
  var storeStatus = "";
  if (Object.keys(state.storedetails).length > 0) {
    if (state.storedetails[0].status === "ok") {
      store_details = state.storedetails[0].result;
      storeStatus = "success";
    } else {
      storeStatus = "fail";
    }
  }
  var categoryListArray = [];
  if (Object.keys(state.categorylist).length > 0) {
    if (state.categorylist[0].status === "ok") {
      categoryListArray = state.categorylist[0].result;
    }
  }
  var productListArray = [];
  if (Object.keys(state.productlist).length > 0) {
    if (state.productlist[0].status === "ok") {
      productListArray = state.productlist[0].result;
    }
  }
  var cartdetailsArr = [];
  if (Object.keys(state.cartdetails).length > 0) {
    if (state.cartdetails[0].status === "ok") {
      cartdetailsArr = state.cartdetails[0].result;
    }
  }
  return {
    storeDetails: store_details,
    storeStatus: storeStatus,
    categoryList: categoryListArray,
    productList: productListArray,
    cartdetails: cartdetailsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStoreDetails: (params) => {
      dispatch({ type: GET_STORE_DETAILS, params });
    },
    getCategoryList: (params) => {
      dispatch({ type: GET_CATEGORY_LIST, params });
    },
    getProductList: (params) => {
      dispatch({ type: GET_PRODUCT_LIST, params });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Restaurants));
