import React, { useEffect, useState } from "react";
import mgreen from "../../common/images/map-pin-green.svg";
import { googleMapApiKey } from "../Settings/Config";

// Fungsi untuk memuat Google Maps API secara dinamis
const loadGoogleMapsScript = () => {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      resolve(window.google);
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&libraries=places`;
    script.async = true;
    script.onload = () => resolve(window.google);
    script.onerror = (error) => reject(error);
    document.head.appendChild(script);
  });
};

const Autocomplete = (props) => {
  const [googleMap, setGoogleMap] = useState(null);

  useEffect(() => {
    loadGoogleMapsScript()
      .then((google) => {
        console.log("Google Maps API loaded:", google);
        setGoogleMap(google);
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });
  }, []);

  useEffect(() => {
    if (!googleMap) return;

    const initializeAutocomplete = () => {
      console.log("initializeAutocomplete");
      const input = document.getElementById("pac-input");
      const options = {
        componentRestrictions: { country: "id" },
      };

      const autocomplete = new googleMap.maps.places.Autocomplete(
        input,
        options
      );

      autocomplete.addListener("place_changed", () => {
        document.getElementById("location-error").style.display = "none";
        const place = autocomplete.getPlace();
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        let countryName = "";
        let cityName = "";
        let postalCode = "";
        let stateName = "";

        if (
          place.address_components &&
          Array.isArray(place.address_components)
        ) {
          place.address_components.forEach((item) => {
            if (item.types) {
              if (item.types.includes("country")) {
                countryName = item.long_name;
              }
              if (item.types.includes("locality") && !cityName) {
                cityName = item.long_name;
              }
              if (item.types.includes("postal_code") && !postalCode) {
                postalCode = item.long_name;
              }
              if (
                item.types.includes("administrative_area_level_1") &&
                !stateName
              ) {
                stateName = item.long_name;
              }
            }
          });
        }
        if (!cityName) {
          cityName = countryName;
        }

        const addressdetails = `${countryName}~~${latitude}~~${longitude}~~${cityName}~~${postalCode}~~${stateName}`;
        props.sateValChange("address", addressdetails);
      });
    };

    initializeAutocomplete();
  }, [googleMap, props]);

  return (
    <div className="pac-cardinfo" id="pac-card">
      <div id="pac-containerinfo">
        <input
          id="pac-input"
          placeholder="Search location..."
          name="location_address"
          type="text"
        />
        <img src={mgreen} className="pin-left" alt="pin" />
        <div id="location-error"></div>
      </div>
    </div>
  );
};

export default Autocomplete;
