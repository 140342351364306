/* Live */
import cookie from "react-cookies";
export const unquieID = "A90F5FAC-8561-41E0-822B-2531CD32CBA7";
export const apiUrl = process.env.REACT_APP_API_URL;
export const mainUrl = process.env.REACT_APP_SITE_MAIN_URL;
export const voucherUrl = process.env.REACT_APP_VOUCHER_URL;
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const CountryName = "Indonesia";
export const productNoImg = "/img/product-noimg.jpg";
export const companyName = "UV";
export const currency = "RP";
export const googleMapApiKey = process.env.REACT_APP_GOOGLEMAPKEY;

var accesstoken = {
  Authorization: cookie.load("token"),
  fcm_token: process.env.REACT_APP_FCMTOKEN,
};
export const headerconfig = {
  headers: accesstoken,
};
