import { takeEvery, call, put } from "redux-saga/effects";
import { GET_VOUCHER_LIST, SET_VOUCHER_LIST } from "../actions";
import { voucherUrl, headerconfig } from "../components/Settings/Config";
import Axios from "axios";

export const watchGetVoucherList = function* () {
  yield takeEvery(GET_VOUCHER_LIST, workerGetVoucherList);
};

function* workerGetVoucherList() {
  try {
    const uri =
      voucherUrl +
      "inventory/v4/member/stock?page=1&size=10&sortBy=&descending=false&search=&type=datatable&status=AVAILABLE&ultra_voucher=NO";
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_VOUCHER_LIST, value: resultArr });
  } catch {
    console.log("Get Voucher failed");
  }
}
