import React, { Component } from "react";
import hoore from "../../common/images/hoorey.svg";

class Home extends Component {
  render() {
    return (
      <div className="main-div success-done">
        <div className="container">
          <div className="textcenter">
            <img src={hoore} className="tick-vector" alt="hoore" />
            <h1>Payment Completed</h1>
            <p>
              Congratulations, everything has been paid off. Please continue to
              see transaction details
            </p>
            <a href={() => false} className="button">
              View Transaction Details
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
