import React from "react";
/* import { render } from "react-dom"; */
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/style.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";
import Home from "./components/Home/Home";
import Eatsarea from "./components/Home/Eatsarea";
import Allrestaurants from "./components/Restaurants/Allrestaurants";
import Restaurants from "./components/Restaurants/Index";
import Cart from "./components/Cart/Cartlist";
import Transactionorder from "./components/Checkout/Transactionorder";
import Ordercompleted from "./components/Checkout/Ordercompleted";
import Paymentdetails from "./components/Home/Paymentdetails";
import Virtualaccount from "./components/Checkout/Virtualaccount";
import Paymentcompleted from "./components/Home/Paymentcompleted";
import Cardlist from "./components/Home/Cardlist";
import Myvoucher from "./components/Discount/Myvoucher";
import Membershiplist from "./components/Home/Membershiplist";
import Membershippoints from "./components/Home/Membershippoints";
import Discountoffer from "./components/Discount/Discountoffer";
import History from "./components/Orders/History";
import Historydetails from "./components/Orders/Historydetails";
import TrackOrder from "./components/Orders/TrackOrder";
import Favouritelist from "./components/Restaurants/Favouritelist";
import Addaddressform from "./components/Home/Addaddressform";
import Addressyet from "./components/Home/Addressyet";
import Addresslist from "./components/Home/Addresslist";
import Deliverystate from "./components/Home/Deliverystate";

import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";

const store = getStore();
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/delivery-state" component={Deliverystate} />
        <Route exact path="/delivery-location" component={Addresslist} />
        <Route exact path="/add-address" component={Addaddressform} />
        <Route exact path="/eatsarea" component={Eatsarea} />
        <Route exact path="/restaurants" component={Allrestaurants} />
        <Route
          exact
          path="/restaurant/:storeSlug/:categorySlug/:productSlug"
          component={Restaurants}
        />
        <Route
          exact
          path="/restaurant/:storeSlug/:categorySlug"
          component={Restaurants}
        />
        <Route exact path="/restaurant/:storeSlug" component={Restaurants} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/favourites" component={Favouritelist} />
        <Route exact path="/transaction-order" component={Transactionorder} />
        <Route exact path="/process-order" component={Virtualaccount} />
        <Route
          exact
          path="/order-completed/:orderID"
          component={Ordercompleted}
        />
        <Route
          exact
          path="/track-order/:orderType/:orderNumber"
          component={TrackOrder}
        />
        <Route
          exact
          path="/history/:orderType/:orderNumber"
          component={Historydetails}
        />
        <Route exact path="/history/:orderType" component={History} />
        <Route exact path="/history" component={History} />
        <Route exact path="/discount" component={Discountoffer} />
        <Route exact path="/my-voucher" component={Myvoucher} />

        <Route exact path="/payment-details" component={Paymentdetails} />
        <Route exact path="/payment-success" component={Paymentcompleted} />
        <Route exact path="/card-list" component={Cardlist} />

        <Route exact path="/membership-list" component={Membershiplist} />
        <Route exact path="/membership-points" component={Membershippoints} />

        <Route exact path="/add-address-yet" component={Addressyet} />

        {/*  Client Panel Start*/}
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>
);
