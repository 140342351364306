import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import cookie from "react-cookies";
import { apiUrl, unquieID } from "../Settings/Config";
import barrow from "../../common/images/back-arrow.svg";
import Autocomplete from "../Layout/Autocomplete";
class Addresslist extends Component {
  constructor(props) {
    super(props);
    var stateName = this.props.location?.state?.statename || "";
    this.state = {
      stateName: stateName,
      address: "",
      latitude: "",
      longitude: "",
      addresssList: [],
    };
    this.loadAddressList();
  }

  sateValChange = (field, value) => {
    if (field === "address") {
      if (value !== "") {
        var address = $("#pac-input").val();
        console.log(address, "addressaddress");
        var addressdetails = value.split("~~");
        this.setState(
          {
            address: address,
            latitude: addressdetails[1],
            longitude: addressdetails[2],
          },
          function () {
            cookie.save("deliveryaddress", this.state.address, { path: "/" });
            cookie.save("deliverycountry", addressdetails[0], { path: "/" });
            cookie.save("deliverystate", addressdetails[5], { path: "/" });
            cookie.save("deliverypostalCode", addressdetails[4], {
              path: "/",
            });
            cookie.save("deliverycity", addressdetails[3], { path: "/" });
            cookie.save("deliveryaddresslat", this.state.latitude, {
              path: "/",
            });
            cookie.save("deliveryaddresslong", this.state.longitude, {
              path: "/",
            });
            this.props.history.push("/add-address");
          }
        );
      }
    }
  };
  loadAddressList() {
    axios
      .get(
        apiUrl +
          "address/getAddressList?unquieid=" +
          unquieID +
          "&customerID=" +
          cookie.load("customerID")
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ addresssList: res.data.result });
        }
      });
  }
  selectAddress(address) {
    cookie.save("deliveryaddress", address.address, { path: "/" });
    cookie.save("deliverycountry", address.country, { path: "/" });
    cookie.save("deliverystate", address.state, { path: "/" });
    cookie.save("deliverypostalCode", address.postal_code, {
      path: "/",
    });
    cookie.save("deliverycity", address.city, { path: "/" });
    cookie.save("deliverydistrict", address.district, { path: "/" });
    cookie.save("deliveryaddresslat", address.latitude, {
      path: "/",
    });
    cookie.save("deliveryaddresslong", address.longitude, {
      path: "/",
    });
    cookie.save("addressID", address.address_id, {
      path: "/",
    });
    this.props.history.push("/eatsarea");
  }
  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/delivery-state"} className="arrow-back">
                <img src={barrow} alt="back" />
              </Link>
            </div>
            <div className="ha-middle">Add Address</div>
            <div className="ha-rhs"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="location-pin-select">
              {this.state.stateName !== "" && (
                <div className="lo-dp">
                  <Link to={"/delivery-state"}>
                    {this.state.stateName}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </Link>
                </div>
              )}
              <div className="lo-search">
                <Autocomplete
                  sateValChange={this.sateValChange}
                  placeholder="Enter your perfect destination."
                />
              </div>
            </div>
            {this.state.addresssList.length > 0 && (
              <div className="add-address-list">
                <ul>
                  {this.state.addresssList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={this.selectAddress.bind(this, item)}
                      >
                        <h3>{item.addressname}</h3>
                        <p>{item.address}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Addresslist;
