import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import { showBtnLoader, hideBtnLoader } from "../Settings/SettingHelper";
import { apiUrl, unquieID } from "../Settings/Config";
import barrow from "../../common/images/back-arrow.svg";
var qs = require("qs");
class Addaddressform extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliverystate: cookie.load("deliverystate") || "",
      deliverycity: cookie.load("deliverycity") || "",
      deliverypostalCode: cookie.load("deliverypostalCode") || "",

      deliverydistrict: "",
      addressname: "",
      addressnote: "",
      errordeliverystate: false,
      errordeliverycity: false,
      errordeliverydistrict: false,
      erroraddressname: false,
      errordeliverypostalCode: false,
      erroraddressnote: false,
    };
    this.handleChangeText = this.handleChangeText.bind(this);
  }
  handleChangeText(event) {
    var value = event.target.value;
    var name = event.target.name;
    this.setState({ [name]: value, ["error" + name]: false });
  }
  saveAddress() {
    var error = 0;
    console.log(this.state.deliverystate, "this.statedeliverystate");
    if (this.state.deliverystate === "") {
      error++;
      this.setState({ errordeliverystate: true });
    } else {
      this.setState({ errordeliverystate: false });
    }
    if (this.state.deliverycity === "") {
      error++;
      this.setState({ errordeliverycity: true });
    } else {
      this.setState({ errordeliverycity: false });
    }
    if (this.state.deliverydistrict === "") {
      error++;
      this.setState({ errordeliverydistrict: true });
    } else {
      this.setState({ errordeliverydistrict: false });
    }
    if (this.state.deliverypostalCode === "") {
      error++;
      this.setState({ errordeliverypostalCode: true });
    } else {
      this.setState({ errordeliverypostalCode: false });
    }
    if (this.state.addressname === "") {
      error++;
      this.setState({ erroraddressname: true });
    } else {
      this.setState({ erroraddressname: false });
    }
    if (this.state.addressnote === "") {
      error++;
      this.setState({ erroraddressnote: true });
    } else {
      this.setState({ erroraddressnote: false });
    }

    if (error === 0) {
      var postObject = {
        unquieid: unquieID,
        customerID: cookie.load("customerID"),
        state: this.state.deliverystate,
        deliverycity: this.state.deliverycity,
        addressname: this.state.addressname,
        address: cookie.load("deliveryaddress"),
        city: this.state.deliverycity,
        district: this.state.deliverydistrict,
        postal_code: this.state.deliverypostalCode,
        country: cookie.load("deliverycountry"),
        latitude: cookie.load("deliveryaddresslat"),
        longitude: cookie.load("deliveryaddresslong"),
        address_nete: this.state.addressnote,
      };
      showBtnLoader("add_address");
      axios
        .post(apiUrl + "address/addAddress", qs.stringify(postObject))
        .then((res) => {
          hideBtnLoader("add_address");
          if (res.data.status === "ok") {
            cookie.save("addressID", res.data.addressID, {
              path: "/",
            });
            this.props.history.push("/eatsarea");
          } else if (res.data.status === "error") {
          }
        });
    }
  }

  render() {
    return (
      <div className="main-div">
        <div className="header-action header-action-center">
          <div className="container">
            <div className="hac-lhs">
              <Link to={"/delivery-location"} className="arrow-back">
                <img src={barrow} alt="back" />
              </Link>
            </div>
            <div className="ha-middle">Add Address</div>
            <div className="ha-rhs"></div>
          </div>
        </div>
        <div className="content-body rel">
          <div className="container">
            <div className="add-address-form">
              <div className="form-control">
                <label>Province</label>
                <input
                  type="text"
                  name="deliverystate"
                  value={this.state.deliverystate}
                  onChange={this.handleChangeText}
                />
                {this.state.errordeliverystate === true && (
                  <span className="error">This field required</span>
                )}
              </div>
              <div className="form-control">
                <label>City</label>
                <input
                  type="text"
                  name="deliverycity"
                  value={this.state.deliverycity}
                  onChange={this.handleChangeText}
                />
                {this.state.errordeliverycity === true && (
                  <span className="error">This field required</span>
                )}
              </div>
              <div className="form-control">
                <label>District</label>
                <input
                  type="text"
                  name="deliverydistrict"
                  value={this.state.deliverydistrict}
                  onChange={this.handleChangeText}
                />
                {this.state.errordeliverydistrict === true && (
                  <span className="error">This field required</span>
                )}
              </div>
              <div className="form-control">
                <label>Postal Code</label>
                <input
                  type="text"
                  name="deliverypostalCode"
                  value={this.state.deliverypostalCode}
                  onChange={this.handleChangeText}
                />
                {this.state.errordeliverypostalCode === true && (
                  <span className="error">This field required</span>
                )}
              </div>
              <div className="form-control">
                <label>Address Name</label>
                <input
                  type="text"
                  name="addressname"
                  value={this.state.addressname}
                  onChange={this.handleChangeText}
                />
                {this.state.erroraddressname === true && (
                  <span className="error">This field required</span>
                )}
              </div>
              <div className="form-control">
                <label>Note</label>
                <textarea name="addressnote" onChange={this.handleChangeText}>
                  {this.state.addressnote}
                </textarea>
                {this.state.erroraddressnote === true && (
                  <span className="error">This field required</span>
                )}
              </div>
              <div className="form-control-btn">
                <a
                  href={() => false}
                  onClick={this.saveAddress.bind(this)}
                  id="add_address"
                  className="button"
                >
                  Save Address
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Addaddressform;
