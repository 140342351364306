import React from "react";
import cookie from "react-cookies";
import $ from "jquery";
var base64 = require("base-64");
/* stripslashes  */
export const stripslashes = function (str) {
  var strN = str || "";
  if (strN !== "") {
    str = str.replace(/\\'/g, "'");
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, "\0");
    str = str.replace(/\\\\/g, "\\");
    return str;
  }
};

/* delivery charge */
export const getCalculatedAmount = function (
  cartDetailsArr,
  deliveryPartner,
  zoneDetails
) {
  var resultArr = [];
  var subTotal = 0;
  var totalItem = 0;
  var deliveryCharge = 0;

  if (Object.keys(cartDetailsArr).length > 0) {
    if (cartDetailsArr.store.length > 0) {
      cartDetailsArr.store.map((store) => {
        if (store.item.length > 0) {
          store.item.map((ProItem) => {
            totalItem = parseInt(totalItem) + parseInt(ProItem.itemQuantity);
            return "";
          });
        }
        return "";
      });
    }
    subTotal = cartDetailsArr.subTotal;
  }
  if (deliveryPartner !== "") {
    deliveryCharge = deliveryPartner.amount;
  }

  var discountDetails = cookie.load("discountDetails") || [];
  var totalDiscount = 0;
  if (discountDetails.length > 0) {
    discountDetails.map((item) => {
      totalDiscount =
        parseFloat(totalDiscount) + parseFloat(item.promotion_amount);
      return "";
    });
  }
  var additional_charge = 0;
  if (zoneDetails?.zone_additional_charge) {
    additional_charge = zoneDetails?.zone_additional_charge;
  }

  var grandTotal =
    parseFloat(subTotal) +
    parseFloat(deliveryCharge) +
    parseFloat(additional_charge) -
    parseFloat(totalDiscount);

  resultArr["subTotal"] = subTotal;
  resultArr["grandTotal"] = grandTotal;
  resultArr["deliveryCharge"] = deliveryCharge;
  resultArr["additional_charge"] = additional_charge;
  resultArr["totalDiscount"] = totalDiscount;
  resultArr["discountDetails"] = discountDetails;

  resultArr["totalItem"] = totalItem;

  return resultArr;
};

/* show Alert */
export const showAlert = function (type, message) {
  if (type === "success") {
    $("body")
      .addClass("message-overlay")
      .append('<div class="message-overlay-light">' + message + "</div>");
  } else {
    $("body")
      .addClass("message-overlay")
      .append(
        '<div class="message-overlay-light overlay-error-msg">' +
          message +
          "</div>"
      );
  }

  setTimeout(function () {
    $("body").removeClass("message-overlay");
    $(".message-overlay-light").remove();
  }, 1000);
};

/* show Custom Alert */
export const showCustomAlert = function (type, message) {
  var clsIdTxt = type === "success" ? "jquery-success-msg" : "jquery-error-msg";
  $(".custom_alertcls").hide();
  $("." + clsIdTxt).html(message);
  $("#" + clsIdTxt).fadeIn();
  setTimeout(function () {
    $(".custom_alertcls").hide();
  }, 6000);
};

/* show Loader */
export const showLoader = function (divRef, type) {
  var btnType = type === "class" ? "." : "#";
  $(btnType + divRef)
    .addClass("overlay-loader")
    .append('<div class="custom-loader"></div>');
};

/* hide Loader */
export const hideLoader = function (divRef, type) {
  var btnType = type === "class" ? "." : "#";
  $(btnType + divRef)
    .find(".custom-loader")
    .remove();
  $(btnType + divRef).removeClass("overlay-loader");
};

/* show Loader */
export const showBtnLoader = function (divRef, type) {
  var btnType = type === "class" ? "." : "#";
  $(btnType + divRef)
    .addClass("overlay-loader")
    .append('<div class="btn-orange-custom-loader"></div>');
};

/* hide Loader */
export const hideBtnLoader = function (divRef, type) {
  var btnType = type === "class" ? "." : "#";
  $(btnType + divRef)
    .find(".btn-orange-custom-loader")
    .remove();

  $(btnType + divRef).removeClass("overlay-loader");
};

/* sample funtion */
export const showPrice = function (price) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = (
    <>
      <sup>Rp</sup> {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
    </>
  );
  return priceTxt;
};
export const showPriceDirect = function (price) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = (
    <>Rp {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</>
  );
  return priceTxt;
};

export const userID = function () {
  return cookie.load("loginID");
};
export const CompanyID = function () {
  return cookie.load("companyID");
};

export const encodeValue = function (value) {
  if (value !== "") {
    return base64.encode(value);
  }
};
export const decodeValue = function (value) {
  if (value !== "") {
    return base64.decode(value);
  }
};

export const capitalizeFirstLetter = function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertTimestamptoTime = function (unixTimestamp) {
  var unixTimestampN = unixTimestamp || "";
  if (unixTimestampN !== "") {
    // Convert to milliseconds and
    // then create a new Date object
    let dateObj = new Date(unixTimestamp * 1000);
    let utcString = dateObj.toUTCString();

    let time = utcString.slice(-11, -4);
    return time;
  }
};
