import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import hoore from "../../common/images/hoorey.svg";
import { apiUrl, mainUrl, unquieID } from "../Settings/Config";
import axios from "axios";
var qs = require("qs");
class Ordercompleted extends Component {
  constructor(props) {
    super(props);
    var orderID = this.props.match?.params?.orderID || "";
    this.state = {
      orderID: orderID,
    };
    if (orderID === "") {
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    this.clearCart();
    this.clearCookidata();
    this.sendMail();
  }
  clearCart() {
    var postObject = {
      unquieid: unquieID,
      customerID: cookie.load("customerID"),
    };
    axios.post(apiUrl + "cart/removeCart", qs.stringify(postObject));
  }
  clearCookidata() {
    cookie.remove("paymentOrderID", { path: "/" });
    window.sessionStorage.removeItem("postObject");
  }
  sendMail() {
    axios.get(
      mainUrl +
        "sendOrderEmail?unquieid=" +
        unquieID +
        "&orderNumber=" +
        this.state.orderID +
        "&method=direct"
    );
  }

  render() {
    return (
      <div className="main-div success-done">
        <div className="container">
          <div className="textcenter">
            <img src={hoore} className="tick-vector" alt="Order Completed" />
            <h1>Order Completed</h1>
            <p>
              Congratulations, everything has been paid off. Please continue to
              see transaction details
            </p>
            <Link to={"/"} className="button">
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Ordercompleted;
